<template>
  <div class="paySuccess">
    <b-card>
      <div class="icon">
        <img src="@/assets/images/paySuccess.png" alt="" />
      </div>
      <div class="details">
        <div>
          <span v-if="$route.query.model_type==6">Application submitted successfully!</span>
          <span v-else>{{ $t("PayMoney.6ad@payment_success") }} </span>
          <span v-if="$route.query.orderId">{{ $t("PayMoney.0a5@orderid") }} :
            {{ $route.query.orderId }}</span>
          <span v-if="$route.query.transactionId">{{ $t("PayMoney.139@paymentid") }} :
            {{ $route.query.transactionId }}</span>
        </div>
      </div>
      <div class="btnGroup">
        <button class="btn btn-home" @click="goBack">Go Back</button>
        <button class="btn btn-primary" @click="pushOrder">{{ $t("PayMoney.2a1@view_order") }}</button>
      </div>
    </b-card>
  </div>
</template>
<script>
  export default {
    data() {
      return {
      };
    },

    mounted() {
      if (
        this.$getlocalStorage("PayId") &&
        this.$getlocalStorage("PayId") != "" &&
        this.$getlocalStorage("PayId") != "undefined"
      ) {
        this.payPalSuccess()
      }
    },

    methods: {
      goBack() {
        let model_type = this.$route.query.model_type
        let router = { 2: "event-detail", 4: "meeting-details", 6: "award-detail" }
        if (!model_type) return this.$router.push('/newsfeeds')
        if (model_type == 5) return this.$router.push('/profile')
        if (model_type == 8) return this.$router.push('/my-donation')
        if (model_type == 9) return this.$router.push('/my-donation')
        if (this.$route.query.model_type == 2) {
          this.$router.push({
            path: "/event-detail",
            query: {
              id: this.$route.query.model_id,
              type: model_type,
            },
          });
        } else {
          this.$router.push({
            path: `${router[model_type]}`,
            query: {
              id: this.$route.query.model_id,
              type: model_type,
            },
          });
        }
      },

      pushOrder() {
        let model_type = this.$route.query.model_type
        // if (["1", "2", "4"].includes(this.$route.query.type)) {
        //   this.type = this.$route.query.type;
        // } else if (this.$route.query.type == "7") {
        //   if (this.$route.query.cType == "paper") {
        //     this.type = 5;
        //   } else if (this.$route.query.cType == "certificate") {
        //     this.type = 6;
        //   }
        // } else if (
        //   this.$route.query.model_type == "6" ||
        //   this.$route.query.type == "6"
        // ) {
        //   this.type = 9;
        // } else if (
        //   this.$route.query.model_type == "3" ||
        //   this.$route.query.type == "3"
        // ) {
        //   this.type = 3;
        // } else if (this.$route.query.type == "8") {
        //   this.type = 7;
        // }
        this.$router.push({
          path: "/MyOtherOrder",
          query: { model_type }
        })
      },

      payPalSuccess() {
        let payId = this.$getlocalStorage("PayId");
        let type = this.$route.query.model_type;
        if (type == 9) {
          this.$http.checkOrderPayPalIsPaid({
            subscription_id: payId,
            token: this.$route.query.token,
          }).then((res) => {
            if (res.status == 200) {
              localStorage.removeItem("PayId");
            }
          });
        } else {
          this.$http.payPalSuccess({
            type,
            order_no: payId,
            paypal_id: this.$route.query.token,
          }).then((res) => {
            if (res.status == 200) {
              localStorage.removeItem("PayId");
            }
          });
        }
      },

      // paypalSuc() {
      //   let payId = this.$getlocalStorage("PayId");
      //   if (payId) {
      //     this.$http.paypalSuc({
      //       order_no: payId,
      //       paypal_id: this.$route.query.token,
      //     }).then((res) => {
      //       if (res.status == 200) {
      //         localStorage.removeItem("PayId");
      //       }
      //     });
      //   }
      // },

    },
  };
</script>

<style lang="less" scoped>
  .paySuccess {
    padding: 20px;

    .card-body {
      padding: 50px;

      .icon {
        text-align: center;
      }

      .details {
        border: 1px solid #49f0d3;
        padding: 10px;
        color: #49f0d3;
        background: rgba(238, 255, 242, 1);
        text-align: center;
        margin-top: 20px;

        span {
          display: block;
        }
      }

      .btnGroup {
        text-align: center;
        padding-top: 30px;

        .btn-home {
          border: 1px solid #50b5ff;
          margin-right: 10px;
          color: #50b5ff;
        }
      }
    }
  }
</style>