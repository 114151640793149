<template>
  <div style="background-color:#fff;width:850px" class='content_exploitation'>
    <div class="pt-5 px-5 pb-5">
      <h4 class="mb-5">Policy on Child Sexual Abuse and Exploitation (CSAE)</h4>
      <p>1.Introduction</p>
      <span>At JCI Virtual Community, the safety and protection of children are of paramount importance. We are
        committed to
        providing a safe environment for all users, particularly minors, by strictly prohibiting any form of child
        sexual abuse
        and exploitation (CSAE). This policy outlines our standards and procedures to prevent, detect, and respond to
        CSAE.</span>
      <p>2.Prohibited Activities</p>
      <span>The following activities are strictly prohibited within JCI Virtual Community:<br />
        Any form of child sexual abuse or exploitation, including but not limited to, the creation, distribution, or
        possession
        of child sexual abuse material (CSAM).<br/>
        Engaging in or facilitating any activities that exploit children or expose them to harm.<br />
        Grooming or attempting to groom minors for sexual purposes.<br />
        Sharing or soliciting sexually explicit images or messages involving minors.</span>
      <p>3.⁠⁠User Obligations</p>
      <span>
        All users of JCI Virtual Community are expected to:<br />
        Adhere to our standards and policies against CSAE.<br />
        Report any instances or suspicions of CSAE encountered within the app.<br />
        Respect the privacy and safety of all members, especially minors.
      </span>
      <p>4.⁠⁠Reporting Mechanisms</p>
      <span>
        We have established multiple mechanisms for users to report CSAE:<br />
        In-app Reporting: Users can report content or behavior that violates our CSAE standards directly through the
        app.<br />
        Dedicated Support Team: Our child safety team is available to handle reports and provide support promptly.<br />
        Anonymous Reporting: Users can report incidents anonymously to ensure their safety and confidentiality.
      </span>
      <p>5.⁠⁠Response and Enforcement</p>
      <span>
        Upon receiving a report of CSAE:<br />
        We will promptly investigate the report and take appropriate action, which may include removing content, banning users,
        and cooperating with law enforcement authorities.<br />
        Our response team is trained to handle CSAE cases with sensitivity and confidentiality, ensuring the protection of
        victims and the integrity of the investigation.
      </span>
      <p>6.⁠⁠Compliance with Laws</p>
      <span>
        JCI Virtual Community complies with all applicable laws and regulations concerning child safety and CSAE. We work
        closely with law enforcement and child protection organizations to address CSAE effectively.
      </span>
      <p>7.⁠⁠Education and Awareness</p>
      <span>
        We are committed to educating our community about CSAE through:<br />
        Regular updates and information on CSAE prevention.<br />
        Resources and guidance for parents, guardians, and users on recognizing and reporting CSAE.
      </span>
      <p>8.⁠⁠Point of Contact</p>
      <span>
        For any concerns or reports related to CSAE, users can contact our child safety team at [help@jci.cc], ensuring prompt
        and professional handling of all cases.
      </span>
      <p>9.⁠⁠Policy Review</p>
      <span>
        This policy is subject to periodic review and updates to ensure its effectiveness in combating CSAE and adapting to new
        challenges and legal requirements.
      </span>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'demo',
    // 组件参数 接收父组件数据
    props: {},
    // 局部注册组件
    components: {},
    // 组件状态值
    data() {
      return {
        // status: 0,
        content: ''
      }
    },
    // 计算属性
    computed: {},
    // 侦听器

    // 组件实例创建完成，DOM未生成，
    created() { },

    // 组件方法
    methods: {

    },
    //组件 DOM 已经更新
    updated() { },
    // 实例销毁之前调用。
    beforeDestroy() { },
    // Vue 实例销毁后调用,事件监听器会被移除
    destroyed() { }
  }

</script>

<style scoped lang='less'>
  .content_exploitation {
    margin: 0 auto;

    p {
      margin-top: 60px;
      margin-bottom: 30px;
    }

    span {
      line-height: 32px;
    }
  }
</style>